import InputMask from "react-input-mask";


import "react-datepicker/dist/react-datepicker.css";

type CalendarProps = {
  date:  string,
  onChange: (value: string) => void
}

function CalendarPicker({date, onChange}: CalendarProps) {

  return (
    <>
    <InputMask
    id={'phoneInput'}
    mask={'99/99/9999'}
    maskChar="_"
    maskPlaceholder={'MM/DD/YYYY'}
    value={date}
    onChange={(event) => {
      onChange(event.target.value)
    }}
    placeholder={'MM/DD/YYYY'}
  />
    </>

  );

}

export default CalendarPicker
