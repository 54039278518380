import React, { useRef, useState } from 'react';
import { typeIcons } from "../../../core/icons";
import { apiProfileUploadAvatar } from "../../../actions/profileActions";
import { useSelector } from "react-redux";
import { getCroppedImageUrl } from "../../../helpers/helpers";

const emptyImage = require('../../../assets/profile/empty.png');
const plusIcon = require('../../../assets/profile/plus.png');

export interface Avatar {
  path: string | null,
  adult: boolean,
  adult_status: number,
}

type ImageUploadType = {
  type: string,
  image: string | null,
  onChange: (image: Avatar) => void
}

function ImageUpload({ type, image, onChange }: ImageUploadType) {

  const token = useSelector((state: any) => state.common.token)

  const [loading, setLoading] = useState(false)

  const inputFile = useRef<any>(null)

  const onFileChange = async (event: any) => {

    if (event.target.files.length > 0) {

      setLoading(true)
      const response = await apiProfileUploadAvatar(token, event.target.files[0])
      if (response.adult && type !== 'fun') {
        alert('This photo violates our Ts & Cs. Please choose another.')
      } else {
        onChange(response)
      }
      setLoading(false)

    }

  }

  return (
    <div className="edit-profile-image-area">
      {loading ? (
        <div>Loading</div>
      ) : (
        <>
          <input
            accept={"image/*"}
            type="file"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={onFileChange}
          />
          <img src={image !== null ? getCroppedImageUrl(image, 276, 276) : emptyImage} alt="edit profile" className="edit-profile-image-area-circle" />
          <img src={plusIcon} alt="plus" className="edit-profile-image-area-plus" onClick={() => {
            inputFile.current.click();
          }} />
          <div className={`edit-profile-image-type-icon ${type}`}>
            <img src={typeIcons[type]} alt="types" className="edit-profile-image-type-icon-image" />
          </div>
        </>
      )}
    </div>
  );
}

export default ImageUpload
