import {
  PRIVACY_POLICY_LINK,
  SITE_URL,
  TERMS_AND_CONDITIONS_LINK
} from "../../../core/links";
import './Footer.css';

const logo = require('../../../assets/logo.png');

function Footer() {
  return (
    <footer className="footer">
      <div className="container with-flex-row">
        <div className="footer-left">
          <a href={SITE_URL} className="footer-left-logo">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="footer-right">
          <span className="footer-left-copyright">© 2024 MUSL. ALL RIGHTS RESERVED</span>
          <div className="footer-left-links">
            <a href={PRIVACY_POLICY_LINK} className="footer-left-link">Privacy Policy</a>
            <span className="footer-left-link-line">|</span>
            <a href={TERMS_AND_CONDITIONS_LINK} className="footer-left-link">Terms & Conditions</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
