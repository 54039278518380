import React, {useEffect} from 'react';
import './Modal.css'

type ModalProps = {
  open: boolean,
  onClose: () => void,
  title?: string | null
  content: any
  hasBottom?: boolean
}

const closeIcon = require('../../../assets/close-white.png');

function Modal({open, onClose, title, content, hasBottom}: ModalProps) {

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }
    if (!open) {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  if (open) {
    return (
      <>
        <div className="modal-fade" onClick={onClose}/>
        <div className="modal-window">
          {title !== undefined && title !== null && (
            <div className="modal-window-top">
              <span className="modal-window-top-title">{title}</span>
              <div className="modal-window-top-close" onClick={onClose}>
                <img src={closeIcon} alt="close" className="modal-window-top-close-icon"/>
              </div>
            </div>
          )}
          <div className={`modal-window-content ${(title === undefined || title === null) && (hasBottom === undefined || !hasBottom) ? 'large' : ''}`}>
            {content}
          </div>
        </div>
      </>
    )
  }

  return <div/>

}

export default Modal;
