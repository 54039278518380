import { useState } from 'react';
import { Link } from "react-router-dom";
import LabelTabs from "../../../components/ui/LabelTabs";
import Field from "../../../components/ui/Field";
import { ConfirmType } from "../../../components/Otp";
import { apiUserConfirmCreate, apiUserRegister } from "../../../actions/userActions";
import { TERMS_AND_CONDITIONS_LINK } from "../../../core/links";

type LoginFieldsType = {
  onSetConfirm: (confirm: ConfirmType | null) => void
  onSetActivationEmail: (email: string | null) => void
}

function RegisterFields({ onSetConfirm, onSetActivationEmail }: LoginFieldsType) {

  const [type, setType] = useState('email')

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const [repeatPassword, setRepeatPassword] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');

  const [phone, setPhone] = useState('+')
  const [phoneError, setPhoneError] = useState('')

  const [accept, setAccept] = useState(false)
  const [acceptError, setAcceptError] = useState('')

  const [submitLoading, setSubmitLoading] = useState(false)

  const submit = async () => {

    let error = false

    if (!accept) {
      setAcceptError('You must accept Terms & Conditions')
      error = true
    }

    if (type === 'email') {
      if (email.length < 3) {
        setEmailError('Invalid email')
        error = true
      }
      if (password.length < 8) {
        setPasswordError('Invalid password')
        error = true
      }
    }

    if (type === 'phone') {
      if (phone.length < 3) {
        setPhoneError('Invalid phone number')
        error = true
      }
    }

    if (error) {
      return
    }

    setSubmitLoading(true)

    try {

      if (type === 'phone') {
        const response = await apiUserConfirmCreate(phone);
        if (response.status !== 'ok') {
          setPhoneError(response.error)
          setSubmitLoading(false)
          return
        }
        onSetConfirm({
          confirm_id: response.confirm_id,
          phone
        })
        setSubmitLoading(false)
      }

      if (type === 'email') {
        const response = await apiUserRegister(email, password);
        if (!response.status) {
          if (response.error === 'email_exist') {
            setEmailError('This email is already registered')
          }
          setSubmitLoading(false)
          return
        }

        onSetActivationEmail(email)
        setSubmitLoading(false)
      }


    } catch {
      // need error exception
      setSubmitLoading(false)
    }


  }

  return (
    <div className="login-page-content-inner">
      <h1 className="login-page-title">Sign Up</h1>
      <div className="login-page-fields">
        <div className="login-page-fields-area">
          <LabelTabs
            tabs={[
              {
                label: 'Email Address',
                type: 'email'
              },
              {
                label: 'Phone',
                type: 'phone'
              }
            ]}
            current={type}
            onChange={(type) => {
              setType(type)
              setPassword('')
            }}
          />
          {type === 'email' && (
            <>
              <Field
                type={'email'}
                className={'login-page-fields'}
                value={email}
                error={emailError}
                onChange={(value) => {
                  setEmail(value);
                  setEmailError('');
                }}
                id={'loginPageEmail'}
              />
              <Field
                label={'Password'}
                type={'password'}
                className={'login-page-fields'}
                value={password}
                error={passwordError}
                onChange={(value) => {
                  setPassword(value);
                  if (value.length < 8) {
                    setPasswordError('Must be at least 8 characters')
                  } else {
                    setPasswordError('')
                  }
                }}
                id={'loginPagePassword'}
              />
              <Field
                label={'Repeat Password'}
                type={'password'}
                className={'login-page-fields'}
                value={repeatPassword}
                error={repeatPasswordError}
                onChange={(value) => {
                  setRepeatPassword(value);
                  if (value !== password) {
                    setRepeatPasswordError('Passwords do not match');
                  } else {
                    setRepeatPasswordError('');
                  }
                }}
                id={'loginPageRepeatPassword'}
              />
            </>
          )}
          {type === 'phone' && (
            <Field
              type={'phone'}
              className={'login-page-fields'}
              value={phone}
              error={phoneError}
              onChange={(value) => {
                setPhone(value);
                setPhoneError('')
              }}
              id={'loginPagePhone'}
            />
          )}
        </div>
        <div className="login-page-submit-area">
          <label className="login-page-checkbox">
            <input type="checkbox" checked={accept} onChange={(event) => {
              setAccept(event.target.checked)
              setAcceptError('')
            }} />
            <div className="login-page-checkbox-checkmark">
              <div className="login-page-checkbox-checkmark-inner" />
            </div>
            <span className="login-page-checkbox-label"> I’ve read and accept the <a href={TERMS_AND_CONDITIONS_LINK} target={"_blank"}>Terms & Conditions</a></span>
          </label>
          <button disabled={submitLoading} className="login-page-submit-button" onClick={submit}>
            {type === 'email' && "Login"}
            {type === 'phone' && "Send code"}
            {submitLoading && <div className="login-page-submit-button-spinner" />}
          </button>
        </div>
        {acceptError !== '' && <div className="login-page-accept-error">{acceptError}</div>}
        <div className="login-page-bottom">
          <span className="login-page-bottom-link">Already have an account? <Link to="/login">LOGIN</Link></span>
        </div>
      </div>
    </div>
  );
}

export default RegisterFields;
